import React, {FC, useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {ReactQueryDevtools} from 'react-query/devtools';
import {library} from '@fortawesome/fontawesome-svg-core';
import {QueryClient, QueryClientProvider} from 'react-query';
import {MainRouter} from './Views/Router';
import {Theme} from './Components';

library.add(fas);
library.add(far);

const queryClient = new QueryClient();

export const App: FC = () => {
  useEffect(() => {
    // TODO: Load the user's session if any
    // loadSession();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
        <MainRouter />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
