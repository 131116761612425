import React from 'react';
import ReactDOM from 'react-dom';
import {Metric} from 'web-vitals';
import reportWebVitals from './reportWebVitals';
import App from './App';
import './reset.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

const metricDetails = {
  // See https://web.dev/fcp/. Has any portion of the page rendered?
  FCP: {name: 'First Contentful Paint', good: 1800, warning: 3000},

  // See https://web.dev/lcp/. Has the largest chunk of content rendered?
  LCP: {name: 'Largest Contentful Paint', good: 2500, warning: 4000},

  // See https://web.dev/cls/. Is the page moving around on the user?
  CLS: {name: 'Cumulative Layout Shift', good: 0.1, warning: 0.25},

  // See https://web.dev/fid/. Is the user trying to click on things whose event handlers are not yet "ready"?
  FID: {name: 'First Input Delay', good: 100, warning: 300},

  // See https://web.dev/time-to-first-byte/. Is the server slow?
  TTFB: {name: 'Time to First Byte', good: 500, warning: 1000},
};
const goodColor = 'color: #0cce6b';
const warnColor = 'color: #ffa400';
const badColor = 'color: #ff4e42';
reportWebVitals((metric: Metric) => {
  const details = metricDetails[metric.name];
  const style = metric.value <= details.good ? goodColor : metric.value <= details.warning ? warnColor : badColor;
  console.log(`%c${details.name}: ${Math.floor(metric.value)}`, style);
});
