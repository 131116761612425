import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {AppPage} from '../../Components';

const Container = styled.div``;

export const SwagPacks: FC = observer(() => {
  return (
    <AppPage>
      <Container>Swag Packs</Container>
    </AppPage>
  );
});
