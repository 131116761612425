import {FC} from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {AppPage, FlexFill, GreyButton} from '../../Components';
import {AppState} from '../../AppState';

const Columns = styled.div`
  flex-direction: row;
`;

const Column = styled.div`
  margin: 0 20px;
  padding: 22px;
  font-size: 18px;
  background: #ccc;
  border-radius: 20px;
`;

export const Home: FC = observer(() => {
  return (
    <AppPage>
      <Columns>
        <Column>
          Left
          <FlexFill />
          <GreyButton
            onClick={action(() => {
              AppState.showingStoreModal = true;
            })}
            style={{alignSelf: 'flex-end'}}>
            More Info
          </GreyButton>
        </Column>

        <Column>
          Right
          <FlexFill />
          <GreyButton
            onClick={action(() => {
              AppState.showingSwagModal = true;
            })}
            style={{alignSelf: 'flex-end'}}>
            More Info
          </GreyButton>
        </Column>
      </Columns>
    </AppPage>
  );
});
