import {action} from 'mobx';
import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {StoreModal, SwagModal} from '../Modals';
import {ShoppingCart} from './ShoppingCart';
import {AppState} from '../AppState';
import {Header} from './Header';
import {Footer} from './Footer';
import {Menu} from './Menu';

const PageWrapper = styled.div`
  flex-direction: column;
  padding-bottom: 140px;
`;

const Container = styled.div`
  flex-direction: row;
  min-height: 500px;
`;

const Content = styled.div`
  padding: 40px;
  flex: 1;
`;

export const AppPage: FC = observer(({children}) => {
  return (
    <PageWrapper>
      <Header />
      <Menu />

      <Container>
        <Content>{children}</Content>

        <ShoppingCart />
        {AppState.showingStoreModal ? (
          <StoreModal
            onClose={action(() => {
              AppState.showingStoreModal = false;
            })}
          />
        ) : (
          <></>
        )}
        {AppState.showingSwagModal ? (
          <SwagModal
            onClose={action(() => {
              AppState.showingSwagModal = false;
            })}
          />
        ) : (
          <></>
        )}
      </Container>

      <Footer />
    </PageWrapper>
  );
});
