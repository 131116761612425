import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react-lite';
import {AppState, showMenu} from '../AppState';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  color: #333;
  z-index: 10;
  background: ${(props) => props.theme.Colors.SecondaryBlue};
  padding: 60px 0 0;
`;

const MenuButton = styled.div`
  position: absolute;
  top: 20px;
  right: 8px;
  z-index: 21;
  width: 44px;
  height: 44px;
`;

const MenuItem = styled.a`
  font-size: 18px;
  padding: 20px;
  color: white;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.Colors.PrimaryBlue};
  }
`;

export const Menu: FC = observer(() => {
  return AppState.showingMenu ? (
    <Wrapper>
      <MenuButton onClick={() => showMenu(false)}>
        <FontAwesomeIcon icon={faTimes} size="3x" color="#ffffff" />
      </MenuButton>

      <MenuItem href="/">Home</MenuItem>
      <MenuItem href="/store-builder">Store Builder</MenuItem>
      <MenuItem href="/swag-packs">Swag Packs</MenuItem>
    </Wrapper>
  ) : (
    <></>
  );
});
