import styled from 'styled-components/macro';

export const RoundedBlueButton = styled.button`
  width: 220px;
  height: 50px;
  flex: 0 0 50px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  color: white;
  background: #3577d4;
  border: none;
`;

export const GreyButton = styled.button`
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #ccc;
  margin: 0 0 0 20px;
`;
