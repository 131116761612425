import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {AppPage} from '../../Components';
import {ProductCard} from './ProductCard';

const Container = styled.div`
  flex-direction: row;
`;

const Sidebar = styled.div`
  flex: 0 0 300px;
  background: #eee;
  border-radius: 10px;
  padding: 20px;
`;

const Products = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StoreBuilder: FC = observer(() => {
  return (
    <AppPage>
      <Container>
        <Sidebar>
          Filters Here
        </Sidebar>
        <Products>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
          <ProductCard/>
        </Products>
      </Container>
    </AppPage>
  );
});
