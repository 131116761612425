import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react-lite';
import {AppState, showCart} from '../AppState';

const Wrapper = styled.div`
  flex: 0;
  width: 0;
  color: #fff;
  padding: 60px 20px;
  transition: all 500ms;
  transform: translate(100%);
  background: ${(props) => props.theme.Colors.SecondaryBlue};

  &.showing {
    flex: 0 0 300px;
    transform: translate(0);
  }
`;

const MenuButton = styled.div`
  position: absolute;
  top: 20px;
  right: 8px;
  z-index: 21;
  width: 44px;
  height: 44px;
`;

export const ShoppingCart: FC = observer(() => {
  return (
    <Wrapper className={AppState.showingCart ? 'showing' : ''}>
      <MenuButton onClick={() => showCart(false)}>
        <FontAwesomeIcon icon={faTimes} size="3x" color="#ffffff" />
      </MenuButton>
      Shopping cart contents
    </Wrapper>
  );
});
