import React, {FC} from 'react';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AppState, showCart, showMenu} from '../AppState';
import {ReactComponent as ReactLogo} from './img/logo.svg';
import {FlexFill} from './Layout';
import {GreyButton} from './Buttons';

const Wrapper = styled.div`
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  background: ${(props) => props.theme.Colors.PrimaryBlue};
  color: ${(props) => props.theme.Colors.PrimaryWhite};
  padding: 5px 10px;
`;

const MyRow = styled(Wrapper)`
  flex: 0;
  align-items: center;
  white-space: nowrap;
`;

const Logo = styled(ReactLogo)`
  height: 40px;
  alignment: left;
`;

const Link = styled(NavLink)`
  margin-left: 20px;
  letter-spacing: 1px;
  font-size: 24px;
  font-weight: 700;
  color: #eee;
  text-decoration: none;

  &.active,
  &:hover {
    color: yellow;
    cursor: pointer;
  }
`;


export const Header: FC = observer(() => {
  return (
    <Wrapper>
      <MyRow>
        <a href="/">
          <Logo />
        </a>
      </MyRow>

      <FlexFill />

      <MyRow>
        <Link to="/store-builder">Store Builder</Link>
        <Link to="/swag-packs">Swag Packs</Link>
        <GreyButton onClick={() => showCart(!AppState.showingCart)}>Toggle Cart</GreyButton>
      </MyRow>
      {/* <MenuButton onClick={() => showMenu(true)}>
        <FontAwesomeIcon icon={faBars} size="2x" color="#ffffff" />
      </MenuButton> */}
    </Wrapper>
  );
});
