import {action, observable} from 'mobx';

export const AppState = observable({
  // Show or hide the top level menu
  showingMenu: false,

  // Show or hide the cart
  showingCart: false,

  // Are we authenticated?
  isAuthenticated: false,

  showingStoreModal: false,
  showingSwagModal: false,
});

export const showMenu = action((show: boolean) => {
  AppState.showingMenu = show;
});

export const showCart = action((show: boolean) => {
  AppState.showingCart = show;
});

export const setAuthenticated = action((authenticated: boolean) => {
  AppState.isAuthenticated = authenticated;
});
