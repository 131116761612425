import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {BrowserRouter, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import * as LoginViews from './Login';
import {AppState} from '../AppState';
import * as HomeViews from './Home';
import {StoreBuilder} from './StoreBuilder';

const AuthRoute: FC<{component: JSX.Element}> = observer(({component}) => {
  let location = useLocation();

  // If the user is not authenticated, redirect to the login view but remember what they were trying to get to
  if (!AppState.isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} />;
  }

  return component;
});

export const MainRouter: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/my-account" element={<AuthRoute component={<LoginViews.MyAccount />} />} />
      <Route path="/login" element={<LoginViews.Login />} />
      <Route path="/sign-up" element={<LoginViews.SignUp />} />

      <Route path="/" element={<HomeViews.Home />} />

      <Route path="/store-builder" element={<StoreBuilder />} />
      <Route path="/swag-packs" element={<HomeViews.SwagPacks />} />
    </Routes>
  </BrowserRouter>
);
