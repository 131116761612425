import React, {FC} from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  flex: 0 0 240px;
  width: 240px;
  height: 330px;
  padding: 20px;
  margin: 0 0 40px 40px;
  border-radius: 30px;
  border: 1px solid #eee;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
`;

const Photo = styled.img`
  margin: 20px 0 0 0;
  max-width: 100%;
  align-self: center;
`;

const Details = styled.div`
  flex: 0;
  flex-direction: row;
`;

const Info = styled.div`
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: 600;
  color: #335544;
`;

const Price = styled.h2`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #666;
`;

const IconButton = styled.button`
  align-self: flex-end;
  background: white;
  color: #3577d4;
  font-size: 24px;
  border: 2px solid #3577d4;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  margin: 0 0 0 10px;
`;

export const ProductCard: FC = () => {
  return (
    <Wrapper>
      <Photo src="https://cdn.shopify.com/s/files/1/0038/1111/5123/products/IMG2_4025_1d512b93-0f6a-4040-8318-ca1dc45d59a3_250x.jpg" />
      <Details>
        <Info>
          <Title>Next Level - Cotton Crew</Title>
          <Price>
            Starting at <strong>$1M</strong>
          </Price>
        </Info>
        <IconButton>+</IconButton>
      </Details>
    </Wrapper>
  );
};
