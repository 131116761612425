import React, {FC} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components/macro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faEnvelope, faKey} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faInstagramSquare} from '@fortawesome/free-brands-svg-icons';

library.add(faEnvelope);

const Wrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  height: 180px;
  align-items: center;
  color: ${(props) => props.theme.Colors.PrimaryWhite};
  background: ${(props) => props.theme.Colors.PrimaryBlue};
  padding: 5px 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
`;

const TextLines = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1px;
  font-size: 18px;
  font-weight: bold;
  grid-gap: 20px;
`;

const AHref = styled.a`
  color: ${(props) => props.theme.Colors.PrimaryWhite};
  background: ${(props) => props.theme.Colors.PrimaryBlue};
`;

export const Footer: FC = observer(() => {
  return (
    <Wrapper>
      <TextLines>The Swan Brothers</TextLines>
      <TextLines>33 Whipple St</TextLines>
      <TextLines>Pittsfield, MA 01201</TextLines>
      <TextLines>(413) 344-9199</TextLines>

      {/* <TextLines>support@theswanbrothers.com</TextLines> */}
      <TextLines>
        <AHref href="mailto: support@theswanbrothers.com">
          <FontAwesomeIcon icon="envelope" size="2x" />
        </AHref>
        <AHref href="https://www.facebook.com/theswanbrothers">
          <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
        </AHref>
        <AHref href="https://www.instagram.com/theswanbrothers">
          <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
        </AHref>
      </TextLines>
      <TextLines style={{fontSize: '10px'}}>Copyright &copy; theswanbrothers, 2021. All Rights Reserved.</TextLines>
    </Wrapper>
  );
});
