import {FC} from 'react';
import styled from 'styled-components/macro';
import {FlexFill, RoundedBlueButton} from '../Components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #3333337f;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  flex: 0 0 600px;
  z-index: 50;
  width: 600px;
  height: 600px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background: white;
  padding: 40px;
`;

export const SampleModal: FC<{onClose: (cancelled: boolean) => void}> = ({onClose}) => {
  return (
    <Overlay
      onClick={(e) => {
        if (e.currentTarget === e.target) onClose(true);
      }}>
      <Wrapper>
        Lots more details about Stores, bigger image(s).
        <FlexFill />
        <RoundedBlueButton style={{alignSelf: 'center'}}>Let's Build One!</RoundedBlueButton>
      </Wrapper>
    </Overlay>
  );
};
